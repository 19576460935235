<template>
    <div class="uk-width-1-1">
        <template v-if="$can('READ', 'worker-tax-deduction')">
            <!-- Header -->
            <p class="uk-text-bold">Tax Deduction Proof Details</p>
            <div
                v-if="$can('READ', 'worker-tax-deduction') || $can('WRITE', 'worker-tax-deduction') || $can('EDIT', 'worker-tax-deduction')"
                class="uk-flex uk-flex-between gap-10px"
            >
                <template v-if="$can('EDIT', 'worker-tax-deduction')">
                    <button
                        v-if="uploadTemplateLoading"
                        type="button"
                        class="uk-button uk-button-default uk-border-rounded"
                        disabled
                    >
                        <span><div uk-spinner="ratio: 0.5"></div></span>
                        <span class="uk-margin-small-left">Loading</span>
                    </button>
                    <button
                        v-else
                        type="button"
                        class="uk-button uk-button-primary uk-border-rounded uk-position-relative"
                        @click="triggerUploadTemplate"
                    >
                        <img
                            class="uk-position-center-left uk-margin-small-left"
                            :src="`${images}/outline-upload.svg`"
                            alt="Upload icon"
                        >
                        <span>Upload Template</span>
                        <input
                            ref="uploadTemplate"
                            type="file"
                            style="display: none"
                            accept=".xls, .xlsx"
                            @change="uploadTemplate"
                        />
                    </button>
                </template>
                <button
                    v-if="downloadReportLoading"
                    type="button"
                    class="uk-button uk-button-default uk-border-rounded"
                    disabled
                >
                    <span><div uk-spinner="ratio: 0.5"></div></span>
                    <span class="uk-margin-small-left">Loading</span>
                </button>
                <button
                    v-else
                    type="button"
                    class="uk-button uk-button-default uk-border-rounded uk-position-relative myrobin-border-blue myrobin-color-blue"
                    @click="downloadTaxReportExcel"
                >
                    <img
                        class="uk-position-center-left uk-margin-small-left" 
                        :src="`${images}/download-outline-blue.svg`"
                        alt="Upload icon"
                    >
                    <span>Download Report</span>
                </button>
                <div v-if="$can('READ', 'worker-tax-deduction')" class="uk-width-expand">
                    <form class="uk-width-expand" @submit.prevent="filterMitraTaxList">
                        <div class="uk-inline uk-width-expand">
                            <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search" />
                            <input
                                class="uk-input uk-width-expand"
                                type="search"
                                aria-label="Not clickable icon"
                                placeholder="Search worker by name"
                                v-model="formSearch.worker_name"
                            />
                        </div>
                    </form>
                </div>
                <!-- button divider -->
                <div class="uk-width-expand"></div>
                <!-- button divider -->
                <div>
                    <button
                        type="button"
                        class="uk-button uk-border-rounded uk-position-relative myrobin-background-yellow myrobin-color-white uk-width-auto uk-margin-small-right"
                        @click="showLogs"
                    >
                        <img
                            class="uk-position-center-left uk-margin-small-left"
                            :src="`${images}/timelapse-logs.svg`"
                            alt="Logs icon"
                        >
                        <span>Error Log</span>
                    </button>
                    <button
                        v-if="$can('WRITE', 'worker-tax-deduction')"
                        type="button"
                        class="uk-button uk-button-primary uk-border-rounded uk-position-relative uk-width-auto"
                        @click="showAddWorkerTaxModalBox"
                    >
                        <img
                            class="uk-position-center-left uk-margin-small-left"
                            :src="`${images}/add-user-outline.svg`"
                            alt="Add user icon"
                        >
                        <span>Add Mitra</span>
                    </button>
                </div>
            </div>
            <!-- End header -->

            <!-- Table worker list -->
            <div
                v-if="$can('READ', 'worker-tax-deduction') || $can('EDIT', 'worker-tax-deduction') || $can('DELETE', 'worker-tax-deduction')"
                class="uk-width-1-1 uk-card uk-card-default uk-margin-top"
            >
                <div class="uk-overflow-auto">
                    <table class="uk-table uk-table-middle uk-table-striped">
                        <thead>
                            <tr>
                                <th class="uk-text-left uk-text-nowrap">Fullname</th>
                                <th class="uk-text-left uk-text-nowrap">Email</th>
                                <th class="uk-text-left uk-text-nowrap">NIK</th>
                                <th class="uk-text-center uk-text-nowrap uk-width-small"></th>
                                <th class="uk-text-center uk-text-nowrap uk-width-small">Action</th>
                            </tr>
                        </thead>
                        <template v-if="tableLoading">
                            <LoadingTable :colspan="7" />
                        </template>
                        <template v-else>
                            <tbody v-if="mitraTaxList.docs.length > 0">
                                <tr v-for="(tax, index) in mitraTaxList.docs" :key="index">
                                    <td class="uk-text-left uk-text-nowrap">{{ tax && tax.fullname || '-' }}</td>
                                    <td class="uk-text-left uk-text-nowrap">{{ tax && tax.email || '-' }}</td>
                                    <td class="uk-text-left uk-text-nowrap">{{ tax && tax.identity_number || '-' }}</td>
                                    <td class="uk-text-center uk-text-nowrap uk-width-small">
                                        <button
                                            type="button"
                                            @click="previewWorkerTax({ mitraTax: tax })"
                                        >
                                            <img
                                                :src="`${images}/visibility.svg`"
                                                alt="View icon"
                                            >
                                        </button>
                                    </td>
                                    <td class="uk-text-center uk-text-nowrap uk-width-small">
                                        <div class="uk-flex uk-flex-column uk-flex-left uk-flex-top gap-10px">
                                            <div v-if="$can('EDIT', 'worker-tax-deduction')" class="uk-width-expand">
                                                <button
                                                    type="button"
                                                    class="uk-button uk-width-expand uk-button-primary uk-border-rounded"
                                                    @click="editWorkerTax({
                                                        action: 'edit-worker-tax',
                                                        mitraTax: tax
                                                    })"
                                                >Edit</button>
                                            </div>
                                            <div v-if="$can('EDIT', 'worker-tax-deduction') && !tax.is_distributed" class="uk-width-expand">
                                                <button
                                                    type="button"
                                                    class="uk-button uk-button-default uk-width-expand uk-border-rounded myrobin-color-blue myrobin-border-blue"
                                                    @click="distributeWorkerTax({
                                                        action: 'distribute-worker-tax',
                                                        mitraTax: tax
                                                    })"
                                                >Send to Mitra</button>
                                            </div>
                                            <div
                                                v-else-if="$can('EDIT', 'worker-tax-deduction') && tax.is_distributed"
                                                class="uk-width-expand"
                                                uk-tooltip="title: Tax proof has been sent for this Mitra; pos: bottom-left"
                                            >
                                                <button
                                                    type="button"
                                                    class="uk-button uk-button-default uk-width-expand uk-border-rounded uk-position-relative myrobin-color-yellow myrobin-border-yellow"
                                                    @click="distributeWorkerTax({
                                                        action: 'distribute-worker-tax',
                                                        mitraTax: tax
                                                    })"
                                                >
                                                    <img class="uk-position-center-left uk-margin-small-left" :src="`${images}/info-green.svg`">
                                                    <span>Send to Mitra</span>
                                                </button>
                                            </div>
                                            <div v-if="$can('DELETE', 'worker-tax-deduction')" class="uk-width-expand">
                                                <button
                                                    type="button"
                                                    class="uk-button uk-width-expand uk-button-danger uk-border-rounded"
                                                    @click="deleteWorkerTax({
                                                        action: 'delete-mitra-tax',
                                                        mitraTax: tax
                                                    })"
                                                >Delete</button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <EmptyTable v-else :colspan="5" empty-text="No entries found" />
                        </template>
                    </table>
                </div>
                <Pagination
                    :total-data="mitraTaxList.totalDocs || 0"
                    :change-limit="changeLimit"
                    :change-page="changePage"
                />
            </div>
            <!-- End table worker list -->
        </template>

        <!-- Handler when user can't access -->
        <div v-else>
            <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                    <span class="uk-text-large uk-text-bold">
                        <p>You are not authorized for read data this page</p>
                    </span>
                </div>
            </div>
        </div>
        <!-- End handler when user can't access -->

        <!-- Tax upload error data modal box -->
        <div id="rejected-data-modal" class="rejected-data-modal uk-flex-top uk-modal-container" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button
                    type="button"
                    class="uk-modal-close-default"
                    uk-close
                    @click="hideFailedUploadModalBox"
                ></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Tax Deduction Proof Error Log</h2>
                </div>
                <div class="uk-modal-body ">
                    <div class="uk-flex uk-flex-middle uk-flex-between">
                        <p class="uk-text-lead uk-margin-remove">Incorrect data</p>
                        <template>
                            <button
                                v-if="downloadRejectedDataButtonLoading"
                                type="button"
                                class="uk-button uk-button-default uk-border-rounded uk-margin-small-left uk-width-1-5"
                                disabled
                            >
                                <span><div uk-spinner="ratio: 0.5"></div></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                            <button
                                v-else
                                type="button"
                                class="uk-button uk-border-rounded uk-button-primary uk-margin-small-left uk-width-1-5 uk-position-relative"
                                @click="downloadRejectedData"
                            >
                                <img
                                    class="uk-position-center-left uk-margin-small-left"
                                    :src="`${images}/download-outline-white.svg`"
                                    alt="Download icon"
                                >
                                <span>Download</span>
                            </button>
                        </template>
                    </div>
                    <div class="uk-overflow-auto uk-margin-small-top">
                        <table class="uk-table uk-table-medium uk-table-striped uk-table-middle">
                            <thead>
                                <tr>
                                    <th class="uk-text-nowrap">Name</th>
                                    <th class="uk-text-nowrap">Email</th>
                                    <th class="uk-text-nowrap">Status</th>
                                </tr>
                            </thead>
                            <tbody v-if="rejectedData.length > 0">
                                <tr v-for="(data, index) in rejectedData" :key="index">
                                    <td class="uk-text-nowrap">{{ data.el["Name"] || '-' }}</td>
                                    <td class="uk-text-nowrap">{{ data.el["Email"] || '-' }}</td>
                                    <td class="uk-text-nowrap">{{ data.notes || '-' }}</td>
                                </tr>
                            </tbody>
                            <EmptyTable v-else :colspan="8" empty-text="No entries found" />
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- End tax upload error modal box -->

        <!-- Add or edit worker modal box -->
        <div id="add-edit-worker-tax-modal" class="add-edit-worker-tax-modal uk-flex-top uk-modal-container" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button
                    type="button"
                    class="uk-modal-close-default"
                    uk-close
                    @click="hideAddWorkerModalBox"
                ></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">{{ actionTypeModalBox == 'edit-worker-tax' ? 'Edit' : 'Add' }} Mitra</h2>
                </div>
                <form data-vv-scope="formAddWorker" @submit.prevent="doAddWorker">
                    <div class="uk-modal-body">
                        <div class="uk-grid-small" uk-grid>
                            <!-- 1st row: email -->
                            <div class="uk-width-1-2">
                                <label for="email" class="uk-form-label">Email <span class="uk-text-danger">*</span></label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    class="uk-input uk-border-rounded"
                                    v-model="formAddWorker.email"
                                    v-validate="{
                                        required: true,
                                        email: true
                                    }"
                                />
                                <span class="uk-text-small uk-text-danger" v-show="errors.has('formAddWorker.email')">
                                    {{ errors.first('formAddWorker.email') }}
                                </span>
                            </div>
                        </div>
                        <!-- Field row divider -->
                        <hr />
                        
                        <div class="uk-grid-small" uk-grid>
                            <!-- 2nd row: mitra data -->
                            <div class="uk-width-1-2">
                                <label for="taxDeductionNumber" class="uk-form-label">Nomor Bukti Potong <span class="uk-text-danger">*</span></label>
                                <input
                                    id="taxDeductionNumber"
                                    name="taxDeductionNumber"
                                    type="text"
                                    class="uk-input uk-border-rounded"
                                    v-model="formAddWorker.tax_deduction_number"
                                    v-validate="{ required: true }"
                                />
                                <span class="uk-text-small uk-text-danger" v-show="errors.has('formAddWorker.taxDeductionNumber')">
                                    {{ errors.first('formAddWorker.taxDeductionNumber') }}
                                </span>
                            </div>
                            <div class="uk-width-1-2">
                                <label for="npwp" class="uk-form-label">NPWP</label>
                                <input
                                    id="npwp"
                                    name="npwp"
                                    type="text"
                                    class="uk-input uk-border-rounded"
                                    v-model="formAddWorker.npwp"
                                />
                            </div>
                            <div class="uk-width-1-2">
                                <label for="fullname" class="uk-form-label">Nama <span class="uk-text-danger">*</span></label>
                                <input
                                    id="fullname"
                                    name="fullname"
                                    type="text"
                                    class="uk-input uk-border-rounded"
                                    v-model="formAddWorker.fullname"
                                    v-validate="{ required: true }"
                                />
                                <span class="uk-text-small uk-text-danger" v-show="errors.has('formAddWorker.fullname')">
                                    {{ errors.first('formAddWorker.fullname') }}
                                </span>
                            </div>
                            <div class="uk-width-1-2">
                                <label for="identityNumber" class="uk-form-label">NIK <span class="uk-text-danger">*</span></label>
                                <input
                                    id="identityNumber"
                                    name="identityNumber"
                                    type="text"
                                    class="uk-input uk-border-rounded"
                                    v-model="formAddWorker.identity_number"
                                    v-validate="{
                                        required: true,
                                        numeric: true,
                                        length: 16
                                    }"
                                />
                                <span class="uk-text-small uk-text-danger" v-show="errors.has('formAddWorker.identityNumber')">
                                    {{ errors.first('formAddWorker.identityNumber') }}
                                </span>
                            </div>
                            <div class="uk-width-1-1">
                                <label for="address" class="uk-form-label">Alamat <span class="uk-text-danger">*</span></label>
                                <input
                                    id="address"
                                    name="address"
                                    type="text"
                                    class="uk-input uk-border-rounded"
                                    v-model="formAddWorker.address"
                                    v-validate="{ required: true }"
                                />
                                <span class="uk-text-small uk-text-danger" v-show="errors.has('formAddWorker.address')">
                                    {{ errors.first('formAddWorker.address') }}
                                </span>
                            </div>
                            <div class="uk-width-1-2 uk-margin-small-top">
                                <label for="isOverseasWp" class="uk-form-label">WP Luar Negeri</label>
                                <div class="uk-margin-small-top">
                                    <input
                                        id="isOverseasWp"
                                        name="isOverseasWp"
                                        type="checkbox"
                                        class="uk-checkbox"
                                        v-model="formAddWorker.is_overseas_wp"
                                    /> Ya
                                </div>
                            </div>
                            <div class="uk-width-1-2">
                                <label for="countryCode" class="uk-form-label">Kode Negara Domisili</label>
                                <input
                                    id="countryCode"
                                    name="countryCode"
                                    type="text"
                                    class="uk-input uk-border-rounded"
                                    :disabled="!formAddWorker.is_overseas_wp"
                                    v-model="formAddWorker.country_code"
                                />
                                <span class="uk-text-small uk-text-danger" v-show="formAddWorker.is_overseas_wp && errors.has('formAddWorker.countryCode')">
                                    {{ errors.first('formAddWorker.countryCode') }}
                                </span>
                            </div>
                        </div>
                        <!-- Field row divider -->
                        <hr />

                        <div class="uk-grid-small" uk-grid>
                            <!-- 3rd row: mitra tax data -->
                            <div class="uk-width-1-2">
                                <label for="taxCode" class="uk-form-label">Kode Pajak</label>
                                <input
                                    id="taxCode"
                                    name="taxCode"
                                    type="text"
                                    class="uk-input uk-border-rounded"
                                    v-model="formAddWorker.tax_code"
                                />
                            </div>
                            <div class="uk-width-1-2">
                                <label for="grossSalary" class="uk-form-label">Jumlah Bruto</label>
                                <input
                                    id="grossSalary"
                                    name="grossSalary"
                                    type="text"
                                    class="uk-input uk-border-rounded"
                                    v-model="formAddWorker.gross_salary"
                                />
                            </div>
                            <div class="uk-width-1-2">
                                <label for="taxDpp" class="uk-form-label">Jumlah DPP</label>
                                <input
                                    id="taxDpp"
                                    name="taxDpp"
                                    type="text"
                                    class="uk-input uk-border-rounded"
                                    v-model="formAddWorker.tax_dpp"
                                />
                            </div>
                            <div class="uk-width-1-2 uk-margin-small-top">
                                <label for="isWithoutNpwp" class="uk-form-label">Tanpa NPWP</label>
                                <div class="uk-margin-small-top">
                                    <input
                                        id="isWithoutNpwp"
                                        name="isWithoutNpwp"
                                        type="checkbox"
                                        class="uk-checkbox"
                                        v-model="formAddWorker.is_without_npwp"
                                    /> Ya
                                </div>
                            </div>
                            <div class="uk-width-1-2">
                                <label for="taxRate" class="uk-form-label">Tarif</label>
                                <input
                                    id="taxRate"
                                    name="taxRate"
                                    type="taxRate"
                                    class="uk-input uk-border-rounded"
                                    v-model="formAddWorker.tax_rate"
                                />
                            </div>
                            <div class="uk-width-1-2">
                                <label for="pphDeduction" class="uk-form-label">Jumlah PPh</label>
                                <input
                                    id="pphDeduction"
                                    name="pphDeduction"
                                    type="text"
                                    class="uk-input uk-border-rounded"
                                    v-model="formAddWorker.pph_deduction"
                                />
                            </div>
                        </div>
                        <!-- Field row divider -->
                        <hr />

                        <div class="uk-grid-small" uk-grid>
                            <!-- 4th row: tax deduction date -->
                            <div class="uk-width-1-2">
                                <label for="taxDeductionDate" class="uk-form-label">Tanggal Bukti Potong</label>
                                <datetime
                                    id="taxDeductionDate"
                                    name="taxDeductionDate"
                                    input-class="uk-input"
                                    placeholder="dd-mm-yyyy"
                                    format="dd-LL-yyyy"
                                    :max-datetime="datetime.now().toISODate()"
                                    type='date'
                                    value-zone='local'
                                    v-model="formAddWorker.tax_deduction_date"
                                ></datetime>
                            </div>
                        </div>
                    </div>
                    <div class="uk-modal-footer">
                        <div class="uk-flex uk-flex-middle uk-flex-right gap-10px">
                            <LoaderButton v-if="addWorkerButtonLoading" />
                            <button
                                v-else
                                type="submit"
                                class="uk-button"
                                :class="[disabledButtonAddMitra ? 'uk-button-default' : 'uk-button-primary']"
                                :disabled="disabledButtonAddMitra"
                            >Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- End add worker modal box -->

        <!-- Upload tax batch modal box -->
        <div id="upload-loader-modal" class="upload-loader-modal uk-flex-top" esc-close="false" bg-close="false" uk-modal>
            <div class="uk-modal-dialog uk-margin-auto-vertical uk-width-1-4@s">
                <div class="uk-modal-body uk-flex uk-flex-column uk-flex-middle uk-flex-center">
                    <div class="loader"></div>
                    <p>Uploading...</p>
                </div>
            </div>
        </div>
        <!-- End upload tax batch modal box -->

    </div>
</template>

<script>
import { PREFIX_IMAGE } from "@/utils/constant";
import { mapActions } from 'vuex';
import formatter from '@/utils/formatter';
import {
    notificationSuccess,
    notificationDangerCustom,
    notificationDanger
} from '@/utils/notification';
import { Datetime } from 'vue-datetime';
import { DateTime } from 'luxon';
import { excelDownloader } from '@/utils/helper';

export default {
    name: 'MitraTaxList',
    components: {
        LoadingTable: () => import('@/components/globals/tables/Loading'),
        Pagination: () => import('@/components/globals/Pagination'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        LoaderButton: () => import('@/components/globals/LoaderButton'),
        Datetime,
    },
    props: {
        mitraTaxList: {
            type: Object,
            required: true,
            default: () => {}
        },
        tableLoading: {
            type: Boolean,
            required: true,
            default: false
        },
        downloadReportLoading: {
            type: Boolean,
            required: true,
            default: false
        },
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            formSearch: {
                worker_name: ''
            },
            datetime: DateTime,
            uploadTemplateLoading: false,
            actionTypeModalBox: null,
            downloadRejectedDataButtonLoading: false,
            filenameExcel: null,
            rejectedData: [],
            addWorkerButtonLoading: false,
            formAddWorker: {
                email: null,
                fullname: null,
                identity_number: null,
                address: null,
                tax_deduction_number: null,
                npwp: null,
                is_without_npwp: false,
                is_overseas_wp: false,
                country_code: null,
                tax_code: null,
                gross_salary: null,
                tax_rate: null,
                tax_dpp: null,
                pph_deduction: null,
                tax_deduction_date: null,
            },
            selectedWorkerTax: {
                tax_id: null,
                email: null,
                fullname: null,
                identity_number: null,
                address: null,
                tax_deduction_number: null,
                npwp: null,
                is_without_npwp: false,
                is_overseas_wp: false,
                country_code: null,
                tax_code: null,
                gross_salary: null,
                tax_rate: null,
                tax_dpp: null,
                pph_deduction: null,
                tax_deduction_date: null,
            },
        };
    },
    computed: {
        disabledButtonAddMitra() {
            if (
                this.formAddWorker.email === null ||
                this.formAddWorker.fullname === null ||
                this.formAddWorker.identity_number === null ||
                this.formAddWorker.tax_deduction_number === null ||
                this.formAddWorker.address === null 
            ) {
                return true;
            } else {
                return false;
            }
        },
    },
    watch: {
        'formAddWorker.gross_salary': {
            handler: function(value) {
                this.formatText(value, 'gross_salary');
            },
            immediate: true
        },
        'formAddWorker.tax_dpp': {
            handler: function(value) {
                this.formatText(value, 'tax_dpp');
            },
            immediate: true
        },
        'formAddWorker.pph_deduction': {
            handler: function(value) {
                this.formatText(value, 'pph_deduction');
            },
            immediate: true
        },
        'formAddWorker.npwp': { // uncheck "is_without_npwp" field if NPWP is present
            handler: function(value) {
                if (value) {
                    this.formAddWorker.is_without_npwp = false;
                }
            },
            immediate: true
        },
        'formAddWorker.is_without_npwp': { // if "is_without_npwp" is checked, empty the NPWP field
            handler: function(value) {
                if (value) {
                    this.formAddWorker.npwp = null;
                }
            },
            immediate: true
        },
    },
    beforeMount() {
        if (window.UIkit.modal('#upload-loader-modal')) window.UIkit.modal('#upload-loader-modal').$destroy(true);
        if (window.UIkit.modal('#rejected-data-modal')) window.UIkit.modal('#rejected-data-modal').$destroy(true);
        if (window.UIkit.modal('#add-edit-worker-tax-modal')) window.UIkit.modal('#add-edit-worker-tax-modal').$destroy(true);
    },
    methods: {
        ...mapActions({
            uploadMonthlyTaxDeductionExcel: 'tax/uploadMonthlyTaxDeductionExcel',
            getTaxBatchUploadErrorLogs: 'tax/getTaxBatchUploadErrorLogs',
            addMitraTaxDeduction: 'tax/addMitraTaxDeduction',
            editMitraTaxDeduction: 'tax/editMitraTaxDeduction',
        }),
        filterMitraTaxList() {
            this.$emit('filter-mitra-tax-list', this.formSearch.worker_name);
        },
        triggerUploadTemplate() {
            this.$refs.uploadTemplate.click();
        },
        async uploadTemplate(event) {
            try {
                if (event && event.target && event.target.files && event.target.files[0]) {
                    await window.UIkit.modal('#upload-loader-modal').show();
                    this.uploadTemplateLoading = true;

                    const filePath = event.target.files[0];
                    const data = new FormData();
                    data.append('id', this.$route.params.id);
                    data.append('excel', filePath);

                    const response = await this.uploadMonthlyTaxDeductionExcel(data);
                    if (response && response.status === 'OK') {
                        await window.UIkit.modal('#upload-loader-modal').hide();
                        if (response.rejected_data && response.rejected_data.length > 0) {
                            this.filenameExcel = filePath?.name;
                            this.rejectedData = response.rejected_data;

                            await window.UIkit.modal('#rejected-data-modal').show();
                        }

                        if (response.message == 'success') {
                            notificationSuccess('Tax template uploaded successfully');
                        } else {
                            notificationSuccess('No tax deduction updated');
                        }
                    } else {
                        notificationDangerCustom('Failed to upload tax template');
                    }
                } else {
                    await window.UIkit.modal('#upload-loader-modal').hide();
                    this.uploadTemplateLoading = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                await Promise.all([
                    window.UIkit.modal('#upload-loader-modal').hide(),
                    this.$emit('get-tax-batch-data')
                ]);
                this.uploadTemplateLoading = false;
            }
        },
        async hideFailedUploadModalBox() {
            try {
                this.filenameExcel = null;
                this.rejectedData = [];
                this.uploadTemplateLoading = false;

                await Promise.all([
                    this.$emit('get-tax-batch-data'),
                    window.UIkit.modal('#upload-loader-modal').hide(),
                    window.UIkit.modal('#rejected-data-modal').hide()
                ]);
            } catch (error) {
                notificationDanger(error);
            }
        },
        async downloadRejectedData() {
            try {
                this.downloadRejectedDataButtonLoading = true;

                const dataExcel = [];
                let obj = {};
                this.rejectedData.forEach((data) => {
                    obj = {
                        "User ID": data.el["User ID"] || null,
                        "Email": data.el["Email"] || null,
                        "Nomor Bukti Potong": data.el["Nomor Bukti Potong"] || null,
                        "NPWP": data.el["NPWP"] || null,
                        "NIK": data.el["NIK"] || null,
                        "Nama": data.el["Nama"] || null,
                        "Alamat": data.el["Alamat"] || null,
                        "WP Luar Negeri": data.el["WP Luar Negeri"] || null,
                        "Kode Negara": data.el["Kode Negara"] || null,
                        "Kode Pajak": data.el["Kode Pajak"] || null,
                        "Jumlah Bruto": data.el["Jumlah Bruto"] || null,
                        "Jumlah DPP": data.el["Jumlah DPP"] || null,
                        "Tanpa NPWP": data.el["Tanpa NPWP"] || null,
                        "Tarif": data.el["Tarif"] || null,
                        "Jumlah PPh": data.el["Jumlah PPh"] || null,
                        "Tanggal Bukti Potong": data.el["Tanggal Bukti Potong"] || null,
                        "Notes": data.notes
                    };

                    dataExcel.push(obj);
                });

                const fileName = `(Rejected data) ${this.filenameExcel}`;
                excelDownloader(dataExcel, fileName);
                notificationSuccess('Rejected data successfully downloaded');
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.downloadRejectedDataButtonLoading = false;
            }
        },
        async showLogs() {
            try {
                const response = await this.getTaxBatchUploadErrorLogs(this.$route.params.id);
                if (
                    response &&
                    response.status === 'OK' &&
                    response.result &&
                    response.result.rejected_data &&
                    Array.isArray(response.result.rejected_data) &&
                    response.result.rejected_data.length > 0
                ) {
                    this.filenameExcel = response.result.filename;
                    this.rejectedData = response.result.rejected_data;
                    await window.UIkit.modal('#rejected-data-modal').show();
                } else {
                    notificationSuccess('Pajak Mitra Berhasil Terupload 100%');
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        downloadTaxReportExcel() {
            this.$emit('download-tax-report-excel');
        },
        async showAddWorkerTaxModalBox() {
            try {
                this.resetFormAddWorker();
                await window.UIkit.modal('#add-edit-worker-tax-modal').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async hideAddWorkerModalBox() {
            try {
                this.actionTypeModalBox = null;
                this.resetSelectedWorkerTax();
                this.resetFormAddWorker();
                await window.UIkit.modal('#add-edit-worker-tax-modal').hide();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doAddWorker() {
            try {
                const validate = await this.$validator.validateAll('formAddWorker.*');
                if (!validate || this.$validator.errors.any('formAddWorker.*')) return;

                this.addWorkerButtonLoading = true;

                if (this.formAddWorker.gross_salary) {
                    this.formAddWorker.gross_salary = this.formAddWorker.gross_salary.replace(/[^0-9]/g, '');
                }
                if (this.formAddWorker.tax_dpp) {
                    this.formAddWorker.tax_dpp = this.formAddWorker.tax_dpp.replace(/[^0-9]/g, '');
                }
                if (this.formAddWorker.pph_deduction) {
                    this.formAddWorker.pph_deduction = this.formAddWorker.pph_deduction.replace(/[^0-9]/g, '');
                }
                if (this.formAddWorker.tax_deduction_date) {
                    this.formAddWorker.tax_deduction_date = this.datetime
                        .fromISO(this.formAddWorker.tax_deduction_date)
                        .setZone("utc", { keepLocalTime: true })
                        .toISO();
                }

                const payload = {
                    batch_id: this.$route.params.id,
                    is_monthly: true,
                    ...this.formAddWorker
                };

                if (this.actionTypeModalBox == 'edit-worker-tax') {
                    payload.tax_id = this.selectedWorkerTax.tax_id;
                    const response = await this.editMitraTaxDeduction(payload);

                    if (response && response.status === 'OK') {
                        notificationSuccess('Mitra tax successfully updated');
                    } else {
                        notificationDangerCustom('Failed to update mitra tax');
                    }
                } else {
                    const response = await this.addMitraTaxDeduction(payload);

                    if (response && response.status === 'OK') {
                        notificationSuccess('Mitra tax added successfully');
                    } else {
                        notificationDangerCustom('Failed to add mitra tax');
                    }
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.addWorkerButtonLoading = false;
                await Promise.all([
                    this.$emit('get-tax-batch-data'),
                    this.hideAddWorkerModalBox(),
                ]);
            }
        },
        async editWorkerTax({ action, mitraTax }) {
            try {
                this.resetSelectedWorkerTax();
                this.resetFormAddWorker();
                this.actionTypeModalBox = action;

                const {
                    _id,
                    email,
                    fullname,
                    identity_number,
                    address,
                    tax_deduction_number,
                    npwp,
                    is_without_npwp,
                    is_overseas_wp,
                    country_code,
                    tax_code,
                    gross_salary,
                    tax_rate,
                    tax_dpp,
                    pph_deduction,
                    tax_deduction_date,
                } = mitraTax;

                this.formAddWorker = {
                    email,
                    fullname,
                    identity_number,
                    address,
                    tax_deduction_number,
                    npwp,
                    is_without_npwp,
                    is_overseas_wp,
                    country_code,
                    tax_code,
                    gross_salary,
                    tax_rate,
                    tax_dpp,
                    pph_deduction,
                    tax_deduction_date
                };
                this.selectedWorkerTax.tax_id = _id;

                await window.UIkit.modal('#add-edit-worker-tax-modal').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        resetFormAddWorker() {
            this.formAddWorker = {
                email: null,
                fullname: null,
                identity_number: null,
                address: null,
                tax_deduction_number: null,
                npwp: null,
                is_without_npwp: false,
                is_overseas_wp: false,
                country_code: null,
                tax_code: null,
                gross_salary: null,
                tax_rate: null,
                tax_dpp: null,
                pph_deduction: null,
                tax_deduction_date: null,
            };
        },
        resetSelectedWorkerTax() {
            this.selectedWorkerTax = {
                tax_id: null,
                email: null,
                fullname: null,
                identity_number: null,
                address: null,
                tax_deduction_number: null,
                npwp: null,
                is_without_npwp: false,
                is_overseas_wp: false,
                country_code: null,
                tax_code: null,
                gross_salary: null,
                tax_rate: null,
                tax_dpp: null,
                pph_deduction: null,
                tax_deduction_date: null,
            };
        },
        formatText(value, formName) {
            const balance = value.toString().replace(/[^0-9]/g, '');
            this.formAddWorker[formName] = formatter.thousandSeparator(balance);
        },
        deleteWorkerTax({ action, mitraTax }) {
            this.$emit('delete-worker-tax', { action, mitraTax });
        },
        previewWorkerTax({ mitraTax }) {
            this.$emit('preview-worker-tax', { mitraTax });
        },
        distributeWorkerTax({ action, mitraTax }) {
            this.$emit('distribute-worker-tax', { action, mitraTax });
        },
        changeLimit(e) {
            this.$emit('change-limit', e);
        },
        changePage(e) {
            this.$emit('change-page', e);
        },
    }
};
</script>

<style scoped>
thead {
    background-color: #0ABAB5;
}
th {
    color: #ffffff;
}

.myrobin-color-blue {
    color: #0275D8;
}
.myrobin-color-yellow {
    color: #FFC737;
}
.myrobin-border-blue {
    border: 1px solid #0275D8;
}
.gap-10px {
    gap: 10px;
}
.loader {
    border: 10px solid #16d8d2;
    border-radius: 50%;
    border-top: 10px solid #0ABAB5;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}
</style>
